<template>
  <a-modal v-model="visible" title="Редактирование" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>

    <a-form-model :model="form">
      <a-form-model-item label="Значение" required v-if="!form.key.includes('logo')">
        <a-input v-model="form.value" />
      </a-form-model-item>

      <a-form-model-item v-else class="text_area" label="Логотип">
        <a-upload
          list-type="picture-card"
          :file-list="fileListMain"
          @change="handleChangeList"
        >
          <div v-if="fileListMain.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import users from '@/mixins/users'
import { getBase64 } from '@/utils'

export default {
  name: 'EditMainSettingsModal',

  mixins: [users],

  data() {
    return {
      form: {
        value: '',
        key: '',
      },
      visible: false,
      fileListMain: [],
    }
  },

  watch: {
    'form.value'(val) {
      if (val && !this.form.key.includes('date')) {
        const replaced = val.toString().replace(/[^0-9.]+/g, '')
        this.$set(this.form, 'value', replaced !== '' ? parseInt(replaced) : '')
      }
    },
  },

  computed: {
    isDisabled() {
      if (this.form.key && this.form.key.includes('logo')) {
        return !this.fileListMain.length
      } else {
        return !this.form.value
      }
    },
  },

  methods: {
    open(record, title) {
      this.visible = true
      this.title = title
      this.form = record
      if (this.form.value.includes('logo')) {
        this.fileListMain.push({
          url: this.form.value,
          uid: '-4',
          name: 'image.png',
          status: 'done',
        })
      }
    },

    close() {
      this.visible = false
      this.form = {
        value: '',
        key: '',
      }
      this.fileListMain = []
      this.title = ''
      this.$emit('getList')
    },

    async submit() {
      if (this.fileListMain.length && this.fileListMain[0].originFileObj) {
        this.form.image = await getBase64(this.fileListMain[0].originFileObj)
      }

      try {
        await this.$services.put(`admin/settings/${this.form.id}`, this.form.image ? { value: 'logo', image: this.form.image } : { value: this.form.value })
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
